<template>
  <section>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:visible', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Criar Serviço
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Nome"
            label-for="service-name"
          >
            <b-form-input
              id="service-name"
              v-model="formData.name"
              autofocus
              trim
              placeholder="Nome do Serviço"
            />
          </b-form-group>

          <b-form-group
            label="Imagem"
            label-for="service-image"
          >
            <b-form-input
              id="service-image"
              v-model="formData.image"
              autofocus
              trim
              placeholder="Imagem do Serviço"
            />
          </b-form-group>

          <b-form-group
            label="Valor"
            label-for="service-value"
          >
            <b-form-input
              id="service-value"
              v-model="formData.value"
              autofocus
              trim
              placeholder="Valor do Serviço"
            />
          </b-form-group>

          <b-form-group
            label="Custos Fixos"
            label-for="service-value"
          >
            <b-form-input
              id="service-value"
              v-model="formData.fixed_costs"
              autofocus
              trim
              placeholder="Custos Fixos"
            />
          </b-form-group>

          <b-form-group
            label="API"
            label-for="service-api-name"
          >
            <b-form-select
              id="service-api-name"
              v-model="selected"
              :state="selected === null ? false : true"
              :options="options"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione uma API!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Código de Serviço"
            label-for="service-api-service-name"
          >
            <b-form-input
              id="service-api-service-name"
              v-model="formData.api_service_name"
              autofocus
              trim
              placeholder="Código de Serviço"
            />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Limpar
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BFormSelectOption
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    created_item: {},
    formData: {
      name: '',
      image: '',
      value: 0,
      api_name: null,
      api_service_name: '',
      deactivated: 1
    },
    options: [
      {
        value: 'smsactivate',
        text: 'SMSACTIVATE'
      },
      {
        value: 'fivesims',
        text: '5sims'
      },
      {
        value: 'simsms',
        text: 'SimSMS'
      }
    ],
    selected: null
  }),
  methods: {
    clearForm () {
      this.formData = {
        name: '',
        image: '',
        api_name: '',
        api_service_name: null,
        fixed_costs: 0,
        deactivated: 1
      }
    },
    async handleSubmit () {
      const optionId = this.$router.currentRoute.params.option_id
      const url = '/admin/service_sms'
      const params = {
        service_option_id: optionId,
        name: this.formData.name,
        image: this.formData.image,
        value: this.formData.value,
        api_name: this.selected,
        api_service_name: this.formData.api_service_name,
        fixed_costs: this.formData.fixed_costs,
        deactivated: 1
      }

      this.$http
        .post(url, params)
        .then(res => {
          this.created_item = res.data
        })
        .finally(() => {
          this.$emit('updated', this.created_item)
          this.$emit('update:visible', false)

          this.$swal({
            title: 'Criado com sucesso!!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            },
            buttonsStyling: false
          })
        })
    }
  }
}
</script>
