<template>
  <section>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:visible', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Editar Serviço
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Nome"
            label-for="service-name"
          >
            <b-form-input
              id="service-name"
              v-model="serviceData.name"
              autofocus
              trim
              placeholder="Nome do Serviço"
            />
          </b-form-group>

          <b-form-group
            label="Imagem"
            label-for="service-image"
          >
            <b-form-input
              id="service-image"
              v-model="serviceData.image"
              autofocus
              trim
              placeholder="Imagem do Serviço"
            />
          </b-form-group>

          <b-form-group
            label="Valor"
            label-for="service-value"
          >
            <b-form-input
              id="service-value"
              v-model="serviceData.value"
              autofocus
              trim
              placeholder="Valor do Serviço"
            />
          </b-form-group>

          <b-form-group
            label="Custos Fixos"
            label-for="service-value"
          >
            <b-form-input
              id="service-value"
              v-model="serviceData.fixed_costs"
              autofocus
              trim
              placeholder="Custos Fixos"
            />
          </b-form-group>

          <b-form-group
            label="API"
            label-for="service-api-name"
          >
            <b-form-select
              id="service-api-name"
              v-model="serviceData.api_name"
              :state="serviceData.api_name === null ? false : true"
              :options="options"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione uma API!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Código de Serviço"
            label-for="service-api-service-name"
          >
            <b-form-input
              id="service-api-service-name"
              v-model="serviceData.api_service_name"
              autofocus
              trim
              placeholder="Código de Serviço"
            />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Limpar
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BFormSelectOption
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    options: [
      {
        value: 'smsactivate',
        text: 'SMSACTIVATE'
      },
      {
        value: 'fivesims',
        text: '5sims'
      },
      {
        value: 'simsms',
        text: 'SimSMS'
      }
    ]
  }),
  methods: {
    clearForm () {
      //
    },
    async handleSubmit () {
      const url = `/admin/service_sms/${this.serviceData.id}`
      const params = {
        name: this.serviceData.name,
        image: this.serviceData.image,
        value: this.serviceData.value,
        api_name: this.serviceData.api_name,
        api_service_name: this.serviceData.api_service_name,
        fixed_costs: this.serviceData.fixed_costs
      }

      this.$http.patch(url, params).finally(() => {
        this.$emit('updated', this.serviceData)
        this.$emit('update:visible', false)

        this.$swal({
          title: 'Atualizado com sucesso!!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          },
          buttonsStyling: false
        })
      })
    }
  }
}
</script>
